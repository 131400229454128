@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Gilroy-Medium" !important;
  scrollbar-width: auto;
  scrollbar-color: var(--primary) var(--white);
}

:root {
  --primary: #0B7770;
  --secondary: #292929;
  --heading: #032D23;
  --para: #4e4e4e;
  --gray: #797D8C;
  --black: #000;
  --white: #fff;
  --cardBG: #F6F8F9;
}

@font-face {
  font-family: "Gilroy-Medium";
  font-weight: 500;
  src: url("/src/assets/fonts/Gilroy-Medium.ttf");
}

a {
  color: black !important;
  text-decoration: none;
}

.background_Image {
  /* overflow: hidden; */
  width: 100vw;
  /* height: 100vh; */
  object-fit: cover;
  background-repeat: no-repeat;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)), url("./assets/images/bg.svg");
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  /* display: none; */
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  border: 2px solid var(--white);
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/

/*--------------------- 
        common css 
----------------------------------*/
.p_relative {
  position: relative;
}

.eye_btn button {
  position: absolute !important;
  right: 17px !important;
  top: 3px !important;
}

.page_heading {
  color: var(--heading);
  font-size: 25px !important;
  font-weight: 700 !important;
  text-transform: capitalize;
  margin-bottom: 3px !important;
}

.page_para {
  color: var(--secondary);
  font-size: 16px !important;
  font-weight: 500 !important;
}

.page_para span {
  color: var(--primary);
}

@media (max-width: 600px) {
  .page_heading {
    font-size: 20px !important;
  }

  .page_para {
    font-size: 14px !important;
  }
}

/*--------------------- 
        common css 
----------------------------------*/

.MuiOutlinedInput-notchedOutline {
  border: unset !important;
}

.MuiDataGrid-cell--textLeft {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  outline: none !important;
}

.login_para {
  color: var(--secondary) !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  padding: 10px 0 10px 8px !important;
  line-height: 26px !important;
}

.login_para span {
  color: var(--primary) !important;
  padding: 0 !important;
}

.login_para .remember {
  padding-top: 1px !important;
}

@media (max-width:600px) {
  .login_para {
    font-size: 16px !important;
  }
}

.add_user {
  position: relative;
  border: 1px solid black;
  border-radius: 50%;
}

.padding_0 {
  padding-top: 0 !important;
  padding-left: 10px !important;
}

.add_user:nth-child(1) {
  right: -30px;
  z-index: 4;
}

.add_user:nth-child(2) {
  right: -20px;
  z-index: 3;
}

.add_user:nth-child(3) {
  right: -10px;
  z-index: 2;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 9px;
  padding-bottom: 9px;
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid transparent;
  border-radius: 5px;
}

.rs-picker-menu {
  z-index: 9999;
}

.rs-btn-sm {
  padding: 0px 10px;
}

.tab .MuiBox-root,
.tab .MuiTabPanel-root {
  padding: 0 !important;
}

/*--------------------- 
        DateRange css 
----------------------------------*/

.date_range button {
  background: var(--white);
  padding: 8px;
  border-radius: 5px;
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.15);
  border: unset !important;
}

.daterangepicker .ranges li.active {
  background-color: var(--primary) !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  right: 20px !important;
  left: unset !important;
  display: none !important;
}

/* .daterangepicker {
  right: 25px !important;
} */

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--primary) !important;
}

.applyBtn {
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

/*--------------------- 
        DateRange css 
----------------------------------*/

#image-upload {
  display: none;
}

.file-upload {
  position: relative;
  text-align: center;
  border: 2px dashed var(--primary);
  cursor: pointer;
}

.channel_connect_icon {
  width: 8px;
  height: 8px;
  background: #449c44;
  border-radius: 4px;
}

td {
  white-space: nowrap;
  padding: 10px 15px !important;
}

.calendar-table td {
  padding: 0 !important;
}

.MuiChip-root {
  margin-left: 0 !important;
}

.MuiMenu-paper {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 8px !important;
}

.MuiMenu-paper ul {
  padding: 5px !important;
}

.channel_button .Mui-disabled {
  background-color: #0B7770 !important;
  color: #fff !important;
}

.MuiAutocomplete-inputRoot {
  padding: 4px 10px !important;
  background: #fff !important;
  border: 1px solid black;
  border-radius: 6px;
}

.report_textarea {
  width: 400px;
  font-size: 16px;
  padding: 8px 12px;
}

.MuiFormLabel-root {
  margin-bottom: 0 !important;
  margin-top: 15px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.leadeStatus .MuiSelect-select {
  border: 1px solid transparent !important
}